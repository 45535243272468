import React, { Component } from "react";
import DarkPlayer from "./DarkPlayer";
import Homepage from "./views/Homepage.js";
import { isMobile } from "react-device-detect";
import store from "./stores/AppStore";
import { observer } from "mobx-react";
import Home from "./components/home/Home"

@observer
class App extends Component {

	state = {
		socket: null,
	} 
	componentDidMount() {
		if (store.inRoom) { 
			this.setState( {socket: store.startWebSocket()})
		};
	}

	render() {
		if (!store.inRoom) return <Homepage />
		return (
			<div> 
				{
					this.state.socket && 
					<DarkPlayer isMobile={isMobile} socket={this.state.socket}/>
				}
			</div>
		)
	}
}

export default App