import React, { Component } from "react";
import Homepage from "../views/Homepage.js";
import ChangePassword from "../views/ChangePassword"
import SoundCloudCallback from '../views/SoundCloudCallback'
import WsApp from "../WSApp.js";
import store from "../stores/AppStore";
import { observer } from "mobx-react";

const MAX_CONNECTED_USERS = 100000;

@observer
class LoadingApp extends Component {

    componentDidMount() {
        // store.getConnectedUsers();
    }

	render() {
        // return <WsApp />
        console.log(window.location)
        if (!store.inRoom) return <Homepage/>
        
        if(window.location.pathname === '/soundcloud-callback') return <SoundCloudCallback></SoundCloudCallback>
        if(window.location.pathname === '/change-password') return <ChangePassword></ChangePassword>
        // if (!store.loaded) return <Loading />
        console.log(store.connectedUsers, store.isPlaying, store.loaded);
        // if (store.connectedUsers > MAX_CONNECTED_USERS && !store.isPlaying) return <Loading locked />
		return (
			<WsApp />
		)
	}
}


export default LoadingApp