import React from 'react';
import ReactDOM from 'react-dom';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import './index.css';
import DarkPlayer from './DarkPlayer';
import Loading from "./components/Loading";
import * as serviceWorker from './serviceWorker';


if (process.env.REACT_APP_NODE_ENV === 'PRODUCTION') {
  Bugsnag.start({
    apiKey: '1a23abffa136b8396d132c5eb0cc848d',
    plugins: [new BugsnagPluginReact()]
})

const ErrorBoundary = Bugsnag.getPlugin('react')
  .createErrorBoundary(React)

ReactDOM.render(<ErrorBoundary>
    <Loading />
  </ErrorBoundary>, document.getElementById('root'));
} else {
  ReactDOM.render(<Loading />, document.getElementById('root'));
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
