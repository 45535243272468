import "./DarkPlayer.scss";
import React, { Component } from "react";
import ChatView from "./components/ChatView";
import store from "./stores/AppStore";
import Home from "./components/home/Home"


class App extends Component {
	state = {
		videoNode: null,
		socket: null,
	}

	componentDidMount() {
		const resize = () => window.dispatchEvent(new Event('resize'));

		window.addEventListener('orientationchange', () => {
			[250, 500, 750].forEach(time => setTimeout(resize, time));
		}, false);
		if (store.inRoom) { 
			if (!this.props.socket) {
				this.setState( {socket: store.startWebSocket()})
			} else {
				this.setState( {socket: this.props.socket})
			}
		};
	}

	setRef = (videoNode) => {
		if (!this.setVideoNode) {
			this.setVideoNode = true;
			this.setState({ videoNode })
		}
	}

	render() {
		return (
			<div className="container">
				{
					this.state.socket && 
					<Home socket={this.state.socket} videoNode={this.state.videoNode} {...this.props}/>
				}
				{/* <DarkPlayer videoNode={this.state.videoNode} {...this.props} /> */}
				<audio ref={this.setRef} className="video-js"></audio>
				<canvas width="300px" height="150px" ></canvas>
			</div>
		)
	}
}

// To have leading zero digits in strings.
function pad(num, size) {
	var s = num + "";
	while (s.length < size) s = "0" + s;
	return s;
}

// ms to time/duration
const sToDuration = function (seconds) {
	var hh = Math.floor(seconds / 3600);
	const mm = Math.floor(seconds / 60) % 60;
	const ss = Math.floor(seconds) % 60;
	return pad(hh, 2) + ':' + pad(mm, 2) + ':' + pad(ss, 2);
}

export default App
