import { observable } from "mobx";
import io from "socket.io-client";
import qs from "qs";
import Bugsnag from '@bugsnag/js'

const { location = {} } = window;
const { pathname = "/", hostname, search, origin } = location;
const searchParsed = qs.parse(search, { ignoreQueryPrefix: true });
const testStream = searchParsed && searchParsed.testStream;
const visOff = searchParsed && searchParsed.visOff && JSON.parse(searchParsed.visOff);
const debugManifestResponse = searchParsed && searchParsed.debugManifestResponse && JSON.parse(searchParsed.debugManifestResponse);
let roomIdUpper = pathname.slice(1, pathname.length)
roomIdUpper = (roomIdUpper.charAt(roomIdUpper.length -1) === '/') ? roomIdUpper.slice(0,-1) : roomIdUpper;
const roomId = roomIdUpper.toLowerCase();
const inRoom = !!roomId;
const apiUrl = process.env.REACT_APP_API_URL;
const wsUrl = process.env.REACT_APP_WS_URL;


class AppStore {
    @observable connectedUsers = 0;
    @observable incognitoCookie = false;
    @observable chatStarted = false;
    @observable numMessages = 0;
    @observable numFireEmojis = 0;
    @observable socket = null;
    @observable doFireAnimation = false;
    @observable loaded = false;
    @observable isPlaying = false; //localStorage.isPlaying;
    @observable streamInfo = [];
    @observable username = '';//localStorage.username;
    visOff = visOff;
    inRoom = inRoom;
    debugManifestResponse = debugManifestResponse;
    testStream = testStream;
    roomId = roomId;
    roomIdUpper = roomIdUpper;
    numClicks = 0;
    timeoutSet = false;
    inIframe = window != window.top;
    disableChat = false;


    onNewMessage = (e, isSenderMessage) => {
        if (!this.chatStarted && !isSenderMessage) {
            this.numMessages = this.numMessages + 1;
        }
        if (e && e.message && e.message.includes("🔥")) {
            const numberOfFireEmojisInMsg = (e.message.split("🔥").length - 1)
            this.numFireEmojis = this.numFireEmojis + numberOfFireEmojisInMsg;
            if (e.username !== this.username) {
                this.triggerFireAnimation()
            }
        }

    }

    onStreamInfoChanged = (e) => {
        this.streamInfo = Object.assign({}, this.streamInfo, e);
        console.log("Stream Info call 1");
        this.setBackgroundImage();
    }

    startWebSocket = () => {
        this.socket = io(wsUrl, {
            query: {
                roomId: roomId,
                // username
            }
        });

        this.socket.on( 'connect',  () => {
            console.log( 'connected to server' );
            this.socket.emit("join")
            this.socket.on("new_chat_message", this.onNewMessage)
            this.socket.on("sender_new_message", (e) => this.onNewMessage(e, true))
            this.socket.on("streamInfo", this.onStreamInfoChanged)
            this.socket.on("total_listeners", this.totalListeners)
            this.getStreamData();
        });
        this.socket.io.on('connect_error', function() {
            console.log("AppStore -> startWebSocket -> connect_error")
            if (process.env.REACT_APP_NODE_ENV === 'PRODUCTION') {
                Bugsnag.notify('Socket error connecting to server')
            }
        });
        this.socket.io.on('error', function() {
            if (process.env.REACT_APP_NODE_ENV === 'PRODUCTION') {
                Bugsnag.notify('Socket error connecting to server')
            }
        });
        if (!navigator.cookieEnabled) {
            this.incognitoCookie = true;
        } else {
            this.isPlaying = localStorage.isPlaying;
            this.username = localStorage.username;
        }
        return this.socket;
    }

    totalListeners = (total) => {
        if (total || total === 0) {
            this.connectedUsers = total;
            this.loaded = true;
        }
    }

    onChatClick = () => {
        const { chatStarted } = this;
        if (!chatStarted) {
            this.startChat();
        } else {
            this.hideChat();
        }
    }

    setIsPlaying = (bool) => {
        if (!this.incognitoCookie) {
            localStorage.isPlaying = bool;
        } 
    }


    startChat = () => {
        this.chatStarted = true;
        this.numMessages = 0;
    }

    hideChat = () => {
        this.chatStarted = false;
    }

    // getConnectedUsers = async () => {
    //     const rawResult = await fetch(`${apiUrl}/listeners/${roomId}/length`);
    //     console.log(rawResult)
    //     if (!rawResult || !rawResult.status === 200) return;
    //     const total = await rawResult.json();
    //     if (total || total === 0) {
    //         this.connectedUsers = total;
    //         this.loaded = true;
    //     }
    // }

    getStreamData = async () => {
        let rawResult;
		try {
			rawResult = await fetch(`${apiUrl}/public-api/stream/${roomId}`);
		} catch (error) {
            console.log("AppStore -> getStreamData -> error", error)
            if (process.env.REACT_APP_NODE_ENV === 'PRODUCTION') {
                Bugsnag.notify(error);
            }
		}
        if (!rawResult || !rawResult.status === 200) return;
        const data = await rawResult.json();
        if (data) {
            this.streamInfo = data.streamInfo && data.streamInfo.length > 0 ? data.streamInfo[0] : [];
            if (this.streamInfo.profileImage) {
                this.setBackgroundImage();
            }
        }
    }

    preloadImage() {
        const imageUrl = `https://${process.env.REACT_APP_S3_BUCKET_URL}/${this.streamInfo._id}/profile/original/${this.streamInfo.profileImage}`;
        // console.log("AppStore -> preloadImage -> process.env.S3_BUCKET_URL", process.env.S3_BUCKET_URL)
        // const imageUrl = `https://gramrphone-develop.s3.us-east-2.amazonaws.com/${this.streamInfo._id}/profile/1800/${this.streamInfo.profileImage}`;
        let img = new Image();
        img.onload = () => {
            document.body.style.background = `linear-gradient(rgba(0, 0, 0, 0.4),rgba(0, 0, 0, 0.4)), url(${imageUrl}) no-repeat center center fixed`;    
            // document.body.style.background = `url(${imageUrl}) no-repeat fixed 0 0 / cover rgba(0, 0, 0, 0.5);`;
            document.body.style.backgroundSize = "cover";
            // document.body.style["position"] = "fixed"
            document.body.style["height"] = "100%"
            document.body.style["overflow"] = "hidden"
            document.body.style["background-repeat"] = "no-repeat";
            document.body.style["background-attachment"] = "fixed";
            document.body.style["-o-background-size"] = "cover";
            document.body.style["-moz-background-size"] = "cover";
            document.body.style["-ms-background-size"] = "cover";
            document.body.style.webkitBackgroundSize = "cover";
        }
        img.onerror = (error) => {
            console.log("AppStore -> preloadImage -> img.onerror -> error", error)
        }
        img.src = imageUrl;
    }

    setBackgroundImage() {
        console.log("Stream Info call : setBackgroundImage : 2");
        const imageUrl = `https://${process.env.REACT_APP_S3_BUCKET_URL}/${this.streamInfo._id}/profile/original/${this.streamInfo.profileImage}`;
        let img = new Image();
        img.onload = () => {
            // this.preloadImage();
        }
        img.onerror = (error) => {
            console.log("AppStore -> setBackgroundImage -> img.onerror -> error", error)
        }
        img.src = imageUrl;
    }

    onFireEmojiClick = () => {
        if (this.socket) {
            this.triggerFireAnimation()
            this.numClicks++;
            if (!this.timeoutSet) {
                this.timeoutSet = true;
                setTimeout(() => this.sendFireEmoji(), 2200);
            }
        }

    }

    sendFireEmoji() {
        const { numClicks } = this;
        let message = "";
        for (let i = 0; i < numClicks; i++) {
            message = message + "🔥";
        }
        this.socket.emit('chat_message', {
            message,
            username: !this.incognitoCookie ? (localStorage.username || "Anonymous") : (this.username || "Anonymous")
        })
        this.timeoutSet = false;
        this.numClicks = 0;
    }

    storeUsername = (uName) => { 
        this.username =  uName;
    }

    triggerFireAnimation = () => {
        if (this.doFireAnimation) return;
        else {
            this.doFireAnimation = true;
            setTimeout(() => this.doFireAnimation = false, 1500)
        }
    }

}

export default new AppStore()