import axios from 'axios';

const fetchClient = () => {
  const defaultOptions = {
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json'
    }
  };

  // Create instance
  const instance = axios.create(defaultOptions);

  // Set the AUTH token for any request
//   instance.interceptors.request.use(async config => {
//     const token =
//       localStorage.getItem('token') || sessionStorage.getItem('token');
//     config.headers.authorization = token ? `Bearer ${JSON.parse(token)}` : '';
//     if (token) {
//       const token = await firebaseService.getCurrentUserIdToken();
//       config.headers.id_token = token;
//       return config;
//     }
//     return config;
//   });

  instance.interceptors.response.use(
    res => res,
    err => {
      if (err && err.response && err.response.data && err.response.data.error) {
        throw new Error(err.response.data.error);
      } else {
        throw err;
      }
    }
  );

  return instance;
};

export default fetchClient();
