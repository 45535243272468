import "./Homepage.scss"
import React, { Component } from "react";
import fetchClient from '../lib/axios';


class SoundCloudCallback extends Component {

	state = {
		error: false,
	}

	componentDidMount() {
		console.log(window.location.href)
		const splitMain = window.location.href.split('#');
		if(splitMain && splitMain.length && splitMain.length > 1) {
			const array = splitMain[1].split('&');
			const filter = array.filter((data) => {
				if (data.indexOf('access_token=') !== -1) {
					return true;
				}
				return false
			});
			if (filter && filter.length) {
				window.location.href = `gramrphoneapp://${filter[0]}`
			} else {
				this.setState({error:true})
			}
		} else {
			this.setState({error:true})
		}
	}


	render() {
		return (
			<React.Fragment>
				{!this.state.error && <div className="homepage-container">
				
				</div>}
				{this.state.error && <div className="homepage-container">
					Something went wrong, please try again.
				</div>}
			</React.Fragment>
		)
	}
}

export default SoundCloudCallback