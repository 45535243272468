import "./Homepage.scss"
import React, { Component } from "react";


class Homepage extends Component {

    componentDidMount() {
		window.open("https://www.gramrphone.com","_self");
    }

	render() {
        return (
			<div className="homepage-container">
				<div className='welcome'>gramrphone.live</div>
				<div className="tag">Live music like you've never heard it before</div>
				<div className="info">
					Interested? Email support@gramrphone.live
				</div>
			</div>
		)
	}
}


export default Homepage