import React, { Component, Fragment } from "react";
import "./ChatView.scss"
import { enableBodyScroll } from "body-scroll-lock";
import { isMobile } from "react-device-detect";
import store from "../stores/AppStore";
import { observer } from "mobx-react";
import mapIcon from "../assets/map-icon.png";

const apiUrl = process.env.REACT_APP_API_URL;
@observer
class ChatView extends Component {

	state = {
		messages: [],
		username: store.username,
		skip: 0,
		messagesCount: 0,
		isMessageFetching: false,
	}
	limit = 50;
	scrolledToBottom = false;
	nameInputRef = {}

	componentDidMount() {
		const targetElement = document.querySelector("#messages-list");
		enableBodyScroll(targetElement)
		this.getMessages()
		const { socket } = this.props;
		let lastFired = new Date().getTime();
		setInterval(() => {
			let now = new Date().getTime();
			if (now - lastFired > 2000 && navigator.appVersion.toLowerCase().indexOf("iphone") != -1) {
				this.setState({ messages: []}, () => {
					this.setState({skip : 0}, () => this.getMessages());
				})
			}
			lastFired = now;
		}, 500);
		if (!this.socket) {
			this.handleSocket(socket);
		}
	}

	async getMessages(skip, scrollHeight) {
		skip = skip || this.state.skip
		const { roomId } = this.props;
		try {
			const response = await fetch(`${apiUrl}/chat?roomId=${roomId}&limit=${this.limit}&skip=${skip}`).then(res => res.json())
			if (response && response.data && response.data.length) {
				for (let index = 0; index < response.data.length; index++) {
					const element = response.data[index];
					this.handleLoadMessage(element, scrollHeight);
				}
			}
			if (response && response.option && response.option.length) {
				this.setState({ messagesCount: response.option[0].count })
			}
			this.setState({ isMessageFetching: false })
		} catch (error) {
			console.log("ChatView -> getMessages -> error", error)

		}
	}


	handleSocket = (socket) => {
		if (!socket || this.socket) return;
		this.socket = socket;
		socket.on("new_chat_message", this.handleNewMessage)
		socket.on("sender_new_message", this.handleNewMessage)
	}

	handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			this.submitComment();
		}
	}

	submitComment = () => {
		const { value } = this.inputRef;
		if (!value) return;
		const { socket } = this.props;
		this.socket.emit('chat_message', {
			message: value,
			username: this.state.username
		})
		setTimeout(() => {
			scrollToBottom("messages-list");
		},500)
		if (this.inputRef) {
			this.inputRef.value = "";
		}
	}

	submitName = () => {
		const { value } = this.nameInputRef;
		const { socket } = this.props;
		const { incognitoCookie } = store;
		if (!value) return;
		if (!incognitoCookie) {  
			localStorage.username = value;
		}
		this.setState({
			username: value
		}, () => {
			if (this.inputRef) {
				this.inputRef.value = ""
			}
		})
	}

	setInputRef = (ref) => this.inputRef = ref;
	setNameInputRef = (ref) => this.nameInputRef = ref;

	handleNewMessage = (msg) => {
		if (msg.count) {
			this.setState({ messagesCount: msg.count })
		}
		const messageIndex = this.state.messages.findIndex((message) => message._id === msg._id);
		if (messageIndex === -1) {
			const { onNewMessage = () => { } } = this.props;
			onNewMessage();
			this.setState(({ messages }) => {
				const newList = [...messages, { ...msg, index: messages.length + 1 }]
				return {
					messages: newList
				}
			})
		}
	}

	handleLoadMessage = (msg) => {
		const messageIndex = this.state.messages.findIndex((message) => message._id === msg._id);
		if (messageIndex === -1) {
			const { onNewMessage = () => { } } = this.props;
			onNewMessage();
			let messages = this.state.messages;
			messages.unshift(msg);
			this.setState({ messages });
		}
	}

	renderMessages = () => {
		const { messages } = this.state;
		return messages.map((msg = {}, i) => {
			const { username, message, _id } = msg
			return (
				<div className="message-container" key={(_id || '').toString()}>
					<div className="username">{username}</div>
					<div  key={(message || '').toString() + i.toString()}>
						<div className="message-text">
							<p>{message}</p>
						</div>
					</div>
				</div>
			)
		})
	}

	render() {
		const { username } = this.state;
		const { chatStarted, streamInfo } = store;

		// if (!chatStarted) return <div style={{ height: "65vh" }} />
		const newMessage = (
			<div className="bottom-container">
				<div className="reply-input reply-btn">
					<input
						ref={this.setInputRef}
						onKeyPress={this.handleKeyPress}
						autoComplete="off"
						className="new-msg-input"
						placeholder="Type a message..."
					/>
					<button
						className="default-button-chat"
						onClick={this.submitComment}
					>
						Send
						</button>
				</div>
				{/* <div className="reply-btn">
					<button
						className="default-button-chat"
						onClick={this.submitComment}
					>
						Send
						</button>
				</div> */}
			</div>
			
		)

		const inputNameContainerStyle = isMobile ? { width: "91%"} : { width: "40%"};

		const enterName = (
			<div className="bottom-container input-name" style={inputNameContainerStyle}>
				<div className="reply-input">
					<input
						ref={this.setNameInputRef}
						autoComplete="off"
						className="new-msg-input"
						placeholder="Type your name"
					/>
				</div>
				<button
					disabled={false}
					className={`join-btn submit-button`}
					onClick={this.submitName}
				>
					Join
				</button>
			</div>
		)

		const statusContainer = (
			<div className="status-container">
				<div className="horizontal-line"></div>
				<div className="message-container">
					<div>
						<div className="username">STATUS</div>
						<div className="message-status">
							{streamInfo.location}
						</div>
					</div>
				</div>
			</div>
		)

		const myFunction = (e) => {
			if (!this.state.isMessageFetching) {
				e.preventDefault();
				e.stopPropagation();
				e.nativeEvent.stopImmediatePropagation();
				let element = e.target
				const scrolllength = element.scrollHeight + element.scrollTop;
				const finalValue = element.clientHeight - scrolllength;
				if ((finalValue >= -5 && finalValue <= 5 ) || (navigator.appVersion.toLowerCase().indexOf("android") !== -1 && element.scrollTop >= 0 && element.scrollTop <= 20)) {
					if (this.state.messagesCount !== this.state.messages.length) {
						this.setState({ isMessageFetching: true })
						this.getMessages(this.state.skip + 1)
						this.setState({skip : this.state.skip + 1});
					}
				}
			}
		}

		const messagesListStyle = isMobile ? { height: "50vh" } : { height: "65vh" };
		const containerStyle = isMobile ? { height: "83%", width: "92vw", "paddingBottom": "0" } : {};
		const containerBottomStyle = isMobile ? { width: "92vw", "paddingBottom": "0" } : {};
		return (
			<React.Fragment>
				<div id="scrolldiv" className="chat-view-container" style={containerStyle}>

					<div onScroll={myFunction} className={`wrapper fade-to-nothing ${!username && 'opaque'}`} id="messages-list" style={messagesListStyle}>
						<div className="main-content" >
							{
								chatStarted ?
									<div>
										{this.renderMessages()}
									</div>
									:
									''
							}
						</div>
					</div>
				</div>
				{ chatStarted && <div className="horizontal-line"></div> }
				<div className="chat-view-container-bottom" style={containerBottomStyle}>
					{chatStarted ? !username ? enterName : newMessage : ""}
				</div>
			</React.Fragment>
			

		)
	}
}


const scrollToBottom = (id) => {
	const elem = document.getElementById(id);
	if (elem) {
		elem.scrollTop = elem.scrollHeight;
	}
}


export default ChatView