import "./Homepage.scss"
import React, { Component } from "react";
import fetchClient from '../lib/axios';


class ChangePassword extends Component {

	state = {
		token: null,
		success: false,
		confirmPassword: '',
		password: '',
		isMatchError: false,
		isLengthError: false,
		isAPIError: null
	}

	passwordRef = {}
	confirmPasswordRef = {}

	componentDidMount() {
		if (window.location.search) {
			this.setState({ token : window.location.search.substring(1).split("=")[1]})
		}
	}

	setPasswordRef = (ref) => this.passwordRef = ref;
	setConfirmPasswordRef = (ref) => this.confirmPasswordRef = ref;

	updateValue = (event) => {
		const { name } = event.target;
		let { value } = event.target;
        console.log("ChangePassword -> updateValue -> value", value)
		this.setState({
			[name]: value,
			isMatchError: false,
			isLengthError: false
		});
	}

	submit = async () => {
		if(!this.state.password) return;
		if(!this.state.confirmPassword) return;
		if(this.state.password.toLowerCase() !== this.state.confirmPassword.toLowerCase()) { this.setState({isMatchError: true});return;};
		if(this.state.password.length < 6) { this.setState({isLengthError: true});return;};
		try {
			const apiResult = await fetchClient.post('reset-password', { password : this.state.password, token : this.state.token})
			this.setState({ success : true, isAPIError: null});
		} catch (error) {
			this.setState({ isAPIError: error.toString() })
            console.log("🚀 ~ file: ChangePassword.js ~ line 45 ~ ChangePassword ~ submit= ~ error", error)
		}
		
	}

	render() {
		return (
			<React.Fragment>
				{!this.state.success && <div className="homepage-container">
					<h3 className="tag" className="title-text">Reset Gramrphone Password</h3>
					<input ref={this.setPasswordRef}
						style={{marginTop: '-5px'}}
						type="password"
						autoComplete="off"
						className="input-password"
						placeholder="Please enter your new password"
						name="password"
						value={this.state.password}
						onChange={(e) => this.updateValue(e)}
						></input>
					<input ref={this.setConfirmPasswordRef}
						style={{marginTop: '-5px'}}
						autoComplete="off"
						type="password"
						className="input-password"
						placeholder="Please retype your new password"
						name="confirmPassword"
						value={this.state.confirmPassword}
						onChange={(e) => this.updateValue(e)}
						></input>
						{this.state.isMatchError && <p style={{color: 'red', fontFamily: 'Inter',marginTop: '-9px' ,width:'20%', fontSize: '16px'}}>Passwords do not match</p>}
						{this.state.isLengthError && <p style={{color: 'red', fontFamily: 'Inter',marginTop: '-9px' ,width:'20%', fontSize: '16px'}}>Password must be six characters</p>}
						{this.state.isAPIError && <p style={{color: 'red', fontFamily: 'Inter',marginTop: '-9px' ,width:'20%', fontSize: '16px'}}>{this.state.isAPIError}</p>}
					<button
						style={{marginTop: '-5px'}}
						className="password-btn"
						onClick={this.submit}
					>
						Confirm
					</button>
				</div>}
				{this.state.success && <div className="homepage-container">
					<p style={{color: 'green', fontFamily: 'Inter', fontSize: '16px'}}>Success! Your Gramrphone password has been reset. You can now go back to the app and sign in with your new password!</p>
					<p style={{fontFamily: 'Inter', fontSize: '16px'}}>If you have any issues please contact us at <a target="_blank" href="mailto:support@gramrphone.live">support@gramrphone.live</a>.</p>
				</div>}
			</React.Fragment>
		)
	}
}

export default ChangePassword