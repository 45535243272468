import "./Chat.scss";
import Bugsnag from '@bugsnag/js'
import store from "../../stores/AppStore";
import Linkify from 'react-linkify';
import React, { Component } from "react";
class Chat extends Component {
    state = {
		messages: [],
		skip: 0,
		messagesCount: 0,
        isMessageFetching: false,
        videoNode: null,
        socket: null,
        username: null,
	}
	limit = 50;
	scrolledToBottom = false;
	nameInputRef = {}

    setInputRef = (ref) => this.inputRef = ref;
	setNameInputRef = (ref) => this.nameInputRef = ref;

	componentDidMount() {
		const { socket } = this.props;
		const { incognitoCookie, username } = store;
		this.getMessages(this.state.skip, true);
		this.handleSocket(socket);
		if (!incognitoCookie) { 
			if (localStorage.username) {
				this.setState({ username: localStorage.username });
			}
		} else {
			if (username) {
				this.setState({ username: username });
			}
		}
	}
	
	scrollToBottom = (id) => {
		const elem = document.getElementById(id);
		if (elem) {
			// document.getElementById(id).scrollIntoView(false)
			this.animateScroll(elem,500);
			// elem.scrollTop = 1000000// elem.scrollHeight - elem.clientHeight;
		}
	}

    async getMessages(skip, initLeval) {
		skip = skip || this.state.skip
		const { roomId } = this.props;
		try {
			let response;
			try {
				response = await fetch(`${process.env.REACT_APP_API_URL}/chat?roomId=${roomId}&limit=${this.limit}&skip=${skip}`).then(res => res.json());
			} catch (error) {
				if (process.env.REACT_APP_NODE_ENV === 'PRODUCTION') { 
					Bugsnag.notify(error);
				}
			}
			if (response && response.data && response.data.length) {
				for (let index = 0; index < response.data.length; index++) {
					const element = response.data[index];
					this.handleLoadMessage(element);
				}
			}
			if (response && response.option && response.option.length) {
				this.setState({ messagesCount: response.option[0].count })
			}
			this.setState({ isMessageFetching: false });
			if(initLeval) {
				setTimeout(() => {this.scrollToBottom("messages-list")}, 1000)
			}
		} catch (error) {
			console.log("ChatView -> getMessages -> error", error)

		}
    }
    
    handleLoadMessage = (msg) => {
		const messageIndex = this.state.messages.findIndex((message) => message._id === msg._id);
		if (messageIndex === -1) {
			// const { onNewMessage = () => { } } = this.props;
			// onNewMessage();
			let messages = this.state.messages;
			messages.unshift(msg);
			this.setState({ messages });
		}
	}

	animateScroll(someElement, duration) {
		const elem = document.getElementById('messages-list');
		var start = elem.scrollTop;
		var end = elem.scrollHeight;
		var change = end - start;
		var increment = 100;
		function easeInOut(currentTime, start, change, duration) {
		  // by Robert Penner
		  currentTime /= duration / 2;
		  if (currentTime < 1) {
			return change / 2 * currentTime * currentTime + start;
		  }
		  currentTime -= 1;
		  return -change / 2 * (currentTime * (currentTime - 2) - 1) + start;
		}
		function animate(elapsedTime) {
		  elapsedTime += increment;
		  var position = easeInOut(elapsedTime, start, change, duration);
		  elem.scrollTop = position;
		  if (elapsedTime < duration) {
			setTimeout(function() {
			  animate(elapsedTime);
			}, increment)
		  } else if (elem && parseInt(elem.scrollHeight)-parseInt(elem.scrollTop) != parseInt(elem.clientHeight)){
			// animate(0);
		  }
		}
		animate(0);
	}
    
    handleSocket = (socket) => {
		if (!socket || this.socket) return;
		this.socket = socket;
		socket.on("new_chat_message", this.handleNewMessage)
		socket.on("sender_new_message", this.handleNewMessage)
    }

    handleNewMessage = (msg) => {
		if (msg.count) {
			this.setState({ messagesCount: msg.count })
		}
		const elem = document.getElementById('messages-list');
		
		if(elem && parseInt(elem.scrollHeight)-parseInt(elem.scrollTop) <= parseInt(elem.clientHeight) + 30 && parseInt(elem.scrollHeight)-parseInt(elem.scrollTop) >= parseInt(elem.clientHeight) - 30   ) {
			setTimeout(() => {this.scrollToBottom("messages-list")}, 500)
		}
		const messageIndex = this.state.messages.findIndex((message) => message._id === msg._id);
		if (messageIndex === -1) {
			const { onNewMessage = () => { } } = this.props;
			onNewMessage();
			this.setState(({ messages }) => {
				const newList = [...messages, { ...msg, index: messages.length + 1 }]
				return {
					messages: newList
				}
			})
		}

		
	}
    
    
    renderMessages = () => {
		const { messages } = this.state;
		return messages.map((msg = {}, i) => {
			const { username, message, _id } = msg
			return (
				<div className="message-container" key={(_id || '').toString()}>
					<div className="username">{username}</div>
					<div  key={(message || '').toString() + i.toString()}>
						<div className="message-text">
						<p>
							<Linkify properties={{ 'target': '_blank', style: { color: 'green' } }}>
								{message}
							</Linkify>
						</p>
						</div>
					</div>
				</div>
			)
		})
    }
    

    submitName = (e) => {
        if (e.key === 'Enter') {
			const { incognitoCookie } = store;
            console.log("submitName -> e", e)
            const { value } = this.nameInputRef;
            console.log("submitName -> value", value)
            const { updateUserName } = this.props;
			if (!value) return;
			if (!incognitoCookie) { 
				localStorage.username = value;
			}
			updateUserName(value)
            this.setState({
                username: value
            }, () => {
                if (this.inputRef) {
                    this.inputRef.value = ""
				}
				setTimeout(() => {this.scrollToBottom("messages-list")}, 500)
            })
        }
	}
	
	submitJoinName = () => {
		const { value } = this.nameInputRef;
		const { incognitoCookie } = store;
		if (!value) return;
		if (!incognitoCookie) { 
			localStorage.username = value;
		}
		
		const { updateUserName } = this.props;
		this.setState({
			username: value
		}, () => {
			if (this.inputRef) {
				this.inputRef.value = ""
			}
			setTimeout(() => { this.scrollToBottom("messages-list") }, 500)
		})
		updateUserName(value);
	}

	scrollFn = (e) => {
		if (!this.state.isMessageFetching) {
			e.preventDefault();
			e.stopPropagation();
			e.nativeEvent.stopImmediatePropagation();
			let element = e.target
			const scrolllength = element.scrollHeight + element.scrollTop;
			const finalValue = element.clientHeight - scrolllength;
			// if ((finalValue >= -5 && finalValue <= 5 ) || (navigator.appVersion.toLowerCase().indexOf("android") !== -1 && element.scrollTop >= 0 && element.scrollTop <= 20)) {
				if (element.scrollTop >= 0 && element.scrollTop <= 50) {
				if (this.state.messagesCount !== this.state.messages.length) {
					this.setState({ isMessageFetching: true })
					this.getMessages(this.state.skip + 1, false)
					this.setState({skip : this.state.skip + 1});
				}
			}
		}
	}

	render() {
		return (
			<React.Fragment>
				<div className="chat">
					{!this.state.username && <div className="joinScreen">
						<div className="chatIntruction">
							Enter your screen name to be part of the live conversation!
								</div>
						<input
							ref={this.setNameInputRef}
							onKeyPress={this.submitName}
							autoComplete="off"
							className="new-msg-input"
							placeholder="Type your name"
						/>
						<div className="joindiv">
							<button className="btn joinBtn" onClick={this.submitJoinName}>Join</button>
						</div>
					</div>}
					{this.state.username && <div onScroll={this.scrollFn} className="chatSection" id="messages-list">
						{this.renderMessages()}
					</div>}
				</div>
			</React.Fragment>
            
		)
	}
}

export default Chat