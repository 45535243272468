
class Visualizer { 
	stopVisualizer = false;

    stopVisalizer = () => {
        this.stopVisualizer = true;
	}
	
	startVisalizer = (start) => {
		try {
			return;
			var canvas = document.querySelector('#canvas');
			var canvasMirror = document.querySelector('#canvasMirror');

			if(canvas && canvasMirror) {

				var ctx = canvas.getContext("2d");
				var ctxMirror = canvasMirror.getContext("2d");
		
				const audioContext = window.AudioContext || window.webkitAudioContext;
				var audioCtx = new audioContext();
				var analyser = audioCtx.createAnalyser();
				var el = document.getElementsByTagName('audio')[0];
				const stream = el.captureStream();
				const audioTracks = stream.getAudioTracks();
				if (audioTracks.length) {
					var source = audioCtx.createMediaStreamSource(stream);
					source.connect(analyser)
				}
		
				analyser.fftSize = 256;
		
				var bufferLength = analyser.frequencyBinCount;
		
		
				var dataArray = new Uint8Array(bufferLength);
				var WIDTH = canvas.width;
				var HEIGHT = canvas.height;
		
				var barWidth = (WIDTH / bufferLength) + 3;
				var barHeight;
				var x = 0;
		
				function roundedRect(ctxi, options) {
		
					ctxi.strokeStyle = options.color;
					ctxi.fillStyle = options.color;
					ctxi.lineJoin = "round";
					ctxi.lineWidth = options.radius;
			
					ctxi.strokeRect(
						options.x+(options.radius*.5),
						options.y+(options.radius*.5),
						options.width-options.radius,
						options.height-options.radius
					);
			
					ctxi.fillRect(
						options.x+(options.radius*.5),
						options.y+(options.radius*.5),
						options.width-options.radius,
						options.height-options.radius
					);
			
					ctxi.stroke();
					ctxi.fill();
			
				  }
		
				function renderFrame() {
					requestAnimationFrame(renderFrame)
			
					// setTimeout(() => requestAnimationFrame(renderFrame), 50)
			
					x = 0;
			
					analyser.getByteFrequencyData(dataArray);
			
					ctx.fillStyle = "black";
					ctx.fillRect(0, 0, WIDTH, HEIGHT);
			
					ctxMirror.fillStyle = "black";
					ctxMirror.fillRect(0, 0, WIDTH, HEIGHT);
			
					for (var i = 0; i < bufferLength; i++) {
					  barHeight = dataArray[i] / 2;
			
					  var r = 255;// barHeight + (25 * (i/bufferLength));
					  var g = 255;// 250 * (i/bufferLength);
					  var b = 255;// 50;
					  
					  if (start) { 
						// ctx.fillStyle = "rgb(" + r + "," + g + "," + b + ")";
						// ctx.fillRect(x, HEIGHT - barHeight/2, barWidth, barHeight);
						roundedRect(ctx, {
						  x: x,
						  y: (HEIGHT - barHeight/2),
						  width: barWidth,
						  height: barHeight,
						  radius: 7,
						  color: "rgb(" + r + "," + g + "," + b + ")"
						});
			  
						// ctxMirror.fillStyle = "rgb(" + 51 + "," + 51 + "," + 51 + ")";
						// ctxMirror.fillRect(x, 0, barWidth, barHeight/2);
			  
						roundedRect(ctxMirror, {
						  x: x,
						  y: -1,
						  width: barWidth,
						  height: barHeight/2,
						  radius: 7,
						  color: "rgb(" + 51 + "," + 51 + "," + 51 + ")"
						});
					  } else {
						roundedRect(ctx, {
							x: x,
							y: (HEIGHT - 9),
							width: barWidth,
							height: 10,
							radius: 7,
							color: "rgb(" + r + "," + g + "," + b + ")"
						  });
		
						  roundedRect(ctxMirror, {
							x: x,
							y: -1,
							width: barWidth,
							height: 10,
							radius: 7,
							color: "rgb(" + 51 + "," + 51 + "," + 51 + ")"
						  });
					  }
			
			
					  x += barWidth + 4;
					}
				  }
				renderFrame();
			}
	

		} catch (e) {
			console.log(e.toString())
		}
	}
}

export default new Visualizer();
